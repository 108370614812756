<template>
    <div>
        <van-overlay :show="showDH" @click="notShow" z-index="5">
            <van-sidebar v-model="activeKey" class="nav-right">
                <van-sidebar-item to="/" title="首页" />
                <van-sidebar-item to="/product" title="车型库" />
                <van-sidebar-item to="/financlal" title="会车金融" />
                <van-sidebar-item to="/news" title="会买车资讯" />
                <van-sidebar-item to="/down" title="下载中心" />
                <van-sidebar-item to="/gy" title="联系我们" />
            </van-sidebar>
        </van-overlay>
    </div>
</template>

<script>
    export default {
        created(){
            switch (this.$route.name) {
                case 'home':
                    this.activeKey=0
                    break;
                case 'product':
                    this.activeKey=1
                    break;
                case 'financlal':
                    this.activeKey=2
                    break;
                case 'news':
                    this.activeKey=3
                    break;
                case 'gy':
                    this.activeKey=4
                    break;
                case 'prodeta':
                    this.activeKey=1
                    break;
                case 'newsInfo':
                    this.activeKey=3
                    break;
                default:
                    break;
            }

        },
        data(){
            return{
                 activeKey:0,
            }
        },
        props:{
            showDH:{
                type:Boolean,
                default:false
            }
        },
        methods:{
            notShow(){
                this.$emit('update:showDH',false)
            },
            
        }
    }
</script>

<style lang="less" scoped>
.nav-right{
    background: white;
    width: 70%;
    float:right;
    height: 100%;
    z-index: 3;
}
</style>
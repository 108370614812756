import axios from 'axios'
import {Toast} from 'vant'

axios.defaults.timeout=600000;
axios.defaults.validateStatus = function (status){
    return status>=200&&status<=500;
}

axios.defaults.withCredentials = true;

axios.interceptors.request.use(config=>{
    return config
},err=>{
    return Promise.reject(err)
})

axios.interceptors.response.use(res=>{
    if (res.status !== 200) {
        Toast.fail(res.msg);
        return Promise.reject(new Error(res.msg))
      }
    return res.data
},error=>{
    return Promise.reject(new Error(error))
})

export default axios;
import request from './axios'
// const BaseURL = '/api'
const BaseURL ='https://app.17hmc.cn/api'
//新闻列表
export const newsList = (name,current,size)=>{
    return request({
        url:BaseURL+'/cb-cms/article/endpoint/list',
        method:'get',
        params:{
            current,
            name,
            size
        }
    })
}

//新闻详情
export const newsInfo = (id)=>{
    return request({
        url:BaseURL+'/cb-cms/article/endpoint/detail',
        method:'get',
        params:{
            id
        }
    })
}

//banner
export const Banner = ()=>{
    return request({
        url:BaseURL+'/website/carouselchart/list',
        method:'get'
    })
}

//金融申请
export const finaceapplication = (form)=>{
    return request({
        url:BaseURL+'/website/finaceapplication/save',
        method:'post',
        data:{
            ...form
        }
    })
}

//在线留言
export const onlinemessage = (form)=>{
    return request({
        url:BaseURL+'/website/onlinemessage/save',
        method:'post',
        data:{
            ...form
        }
    })
}

//加盟合作
export const joinmessage = (form)=>{
    return request({
        url:BaseURL+'/website/franchisecooperation/save',
        method:'post',
        data:{
            ...form
        }
    })
}

// 车型列表
export const carList = (current,size)=>{
    return request({
        url:BaseURL+'/website/vehiclemodel/list',
        method:'get',
        params:{
            current,
            size
        }
    })
}
// 车型详情
export const carDetail = (vehicleModelId)=>{
    return request({
        url:BaseURL+'/website/vehiclemodel/detail',
        method:'get',
        params:{
            vehicleModelId
        }
    })
}
//预约看车
export const carviewingappointment = (form)=>{
    return request({
        url:BaseURL+'/website/carviewingappointment/save',
        method:'post',
        data:{
            ...form
        }
    })
}
//获取安卓最新版app安装包
export const androidApp = ()=>{
    return request({
        url:BaseURL+'/cb-cms/app-version/endpoint/new-version',
        method:'get'
    })
}
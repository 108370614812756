<template>
<div>
    <van-sticky z-index="4">
    <div class="header">
        <div class="w1200">
        <div class="head-left" @click="home">
            <img src="../assets/logo.png">
        </div>
        <div class="head-right">
            <div class="nav-bar" @click="zhankai">
                <i></i>
                <i></i>
                <i></i>
            </div>
        </div>

        <div class="navlist">
            <router-link :to="'/'">
                <div class="nav-item" :class="{'cur':showcur==0}">
                    网站首页
                </div>
            </router-link>

            <router-link :to="{path:'/product'}">
                <div class="nav-item" :class="{'cur':showcur==1}">
                    车型库
                </div>
            </router-link>

            <router-link :to="{path:'/financlal'}">
                <div class="nav-item" :class="{'cur':showcur==2}">
                    会车金融
                </div>
            </router-link>
            
            <router-link :to="{path:'/news'}">
                <div class="nav-item" :class="{'cur':showcur==3}">
                    会买车资讯
                </div>
            </router-link>
                
            <router-link :to="{path:'/gy'}">
                <div class="nav-item" :class="{'cur':showcur==4}">
                    联系我们
                </div>
            </router-link>
        </div>
    </div>
    </div>
    </van-sticky>
    <sideBar :showDH.sync="show"/>
</div>
</template>

<script>
import sideBar from '@/components/home/sidebar.vue'
    export default {
        components:{
            sideBar
        },
        created(){
            switch (this.$route.name) {
                case 'home':
                    this.showcur=0
                    break;
                case 'product':
                    this.showcur=1
                    break;
                case 'financlal':
                    this.showcur=2
                    break;
                case 'news':
                    this.showcur=3
                    break;
                case 'gy':
                    this.showcur=4
                    break;
                case 'prodeta':
                    this.showcur=1
                    break;
                case 'newsInfo':
                    this.showcur=3
                    break;
                default:
                    break;
            }
        },
        data(){
            return{
               show:false,
               showcur:0
            }
        },
        methods:{
            zhankai(){
                this.show=true
            },
            home(){
                if(this.$route.path==='/'){
                    return;
                }
                this.$router.push('/')
            },
            
        }
    }
</script>

<style lang="less" scoped>
.header{
    background-color: rgba(255,0,0,.8);
    .w1200{
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding:10px;
    .head-left{
        display: flex;
        align-items: center;
        img{
            height: 50px;
            display: inline-block;
        }
    }
    .head-right{
        display: flex;
        flex-direction: column-reverse;
        .nav-bar{
            width: 30px;
            i{
                display: block;
                height: 3px;
                background-color: #000;
                width:80%;
                margin-bottom: 3px;
            }
        }
    }
    .navlist{
        display: none;
        .nav-item{
            padding:10px 20px;
            margin-right: 8px;
            border-radius: 10px;
            &:hover{
                background: #a60007;
            }
        }
        a{
            color:#fff;
            display: inline-block;
            font-size: 16px;
        }
        .cur{
            background: #a60007;
        }
    }
}
}

@media all and(min-width:640px) {
    .header{
        .w1200{
            height: 100px;
            .head-left{
                img{
                    height: 70px;
                }
            }
            .head-right{
                .nav-bar{
                    display: none;
                }
            }
            .navlist{
                display: flex;
            }
        }
    }
}
</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/index.less'
import flag from '@/utils/yd'

import {Button, Sidebar, SidebarItem,Overlay,Swipe, SwipeItem,Grid, GridItem,Sticky,Form,Field,Icon,Toast,Pagination,Tab, Tabs,Area,Popup,Picker,Dialog,Calendar} from 'vant'
const vantArr = [
  Button,Sidebar,SidebarItem,Overlay,Swipe, SwipeItem,Grid, GridItem,Sticky,Form,Field,Icon,Toast,Pagination,Tab, Tabs,Area,Popup,Picker,Dialog,Calendar
]
Vue.prototype.$flag = flag
vantArr.forEach(e=>Vue.use(e))

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

<template>
    <div>
        <div class="footer-brand">
            <img src="@/assets/ftlogo.jpg" alt="">
        </div>
        <div class="dianhua">
            <p>
                咨询电话：<span>400-082-3168</span>
            </p>
        </div>
<tab-bar/>

<div class="copyright"><p>版权所有:广东会买车网络科技有限公司 </p>
<p></p><p><span style="color: #fff; font-family: &quot;Microsoft YaHei&quot;;">粤ICP备18110487号</span></p><p></p>
</div>
    </div>
</template>

<script>
import tabBar from '@/components/tabbar.vue'
    export default {
        components:{
            tabBar
        }
    }
</script>

<style lang="less" scoped>
.footer-brand{
    padding:10px 0;
    text-align: center;
    border-top: 1px solid #f2f2f2;
    background: #fff;
    img{
        max-height: 30px;
        display: inline-block;
    }
}
.dianhua{
    background: #dfdfdf;
    font-size: 13px;
    color:#999;
    padding: 10px;
}
.copyright{
    background: #000;
    font-size: 14px;
    color: #fff;
    line-height: 2;
    text-align: center;
    padding: 15px 0;
    display: none;
}
@media all and(min-width:640px) {
    .footer-brand{
        img{
            max-height: none;
        }
    }
    .copyright{
        display: block;
    }
    .dianhua{
        background: #282828;
        color: #fff;
        font-size: 16px;
        text-align: center;
        padding:10px 0;
        line-height: 2;
        p{
            max-width: 200px;
            margin:0 auto;
            span{
            display: block;
            background: #e6222a;
            padding:0 22px;
            line-height: 42px;
            border-radius: 42px;
            }
        }
    }
}
</style>
<template>
    <div>
            <div class="title">
                <h3>今日特价</h3>
                <p>Today's deal</p>
            </div>
            <div class="tejia-table">
                <div class="tejiao-table-td">车型</div>
                <div class="tejiao-table-td">描述</div>
                <div class="tejiao-table-td">首付</div>
            </div>
            <div class="hdswi">
                <vue-seamless-scroll :data="listData" :class-option="defaultOption" >
                    <ul class="ul-scoll">
                        <li v-for="item in listData" :key='item.id'>
                            <div class="tejia-table-td ell">{{item.modelName}}</div>
                            <div class="tejia-table-td ell">{{item.description}}</div>
                            <div class="tejia-table-td ell">{{item.downPayment}}</div>
                        </li>
                    </ul>
                </vue-seamless-scroll>
            </div>
    </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import {carList} from '@/utils/requ'
    export default {
        components:{
            vueSeamlessScroll
        },
        computed: {
            defaultOption () {
                return {
                    step: 1, // 数值越大速度滚动越快
                    limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
                    hoverStop: false, // 是否开启鼠标悬停stop
                    direction: 1, // 0向下 1向上 2向左 3向右
                    openWatch: true, // 开启数据实时监控刷新dom
                    singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                    singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                    waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
                }
            }
 
        },
        created(){
            this.onload()
        },
        data() {
            // 这里存放数据
            return {
                listData: []
            }
        },
        methods:{
            async onload(){
                await carList().then(res=>{
                    this.listData = res.data.records
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .title{
        padding: 20px 0 25px;
        text-align: center;
        font-size: 12px;
        color:#888888;
        text-transform: uppercase;
        h3{
            font-size: 15px;
            color: #333333;
            letter-spacing: 2px;
        }
    }
    .tejia-table{
        background-color: #e6222a;
        display: flex;
        text-align: center;
        height: 40px;
        line-height: 40px;
        .tejiao-table-td{
            flex: 1;
            color:white
        }
    }
    .hdswi{
        overflow: hidden;
        height: 280px;
        border-left: 1px solid #d8d8d8;
        border-right: 1px solid #d8d8d8;
        border-bottom: 1px solid #d8d8d8;
        padding-bottom: 20px;
        .ul-scoll li{
            display: flex;
            &:nth-of-type(odd){
                background-color: #f5f5f5;
            }
            .tejia-table-td{
                text-align: center;
                flex: 1;
                padding:0 5px;
                font-size: 13px;
                color: #4d4d4d;
                height: 40px;
                line-height: 40px;
            }
        }
    }
    @media all and(min-width:640px) {
        .title{
        padding: 50px 0;
        font-size: 28px;
        h3{
            font-size: 30px;
            margin-bottom: 15px;
        }
        }
        .hdswi{
            height: 500px;
            .ul-scoll li{
                .tejia-table-td{
                    font-size: 16px;
                }
            }
        }
    }
</style>
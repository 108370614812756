let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
let mmta;
  if (flag) {
    //   alert("手机端");
      mmta='移动'
    } else {
    //   alert("pc端");
      mmta='pc'
    }
export default mmta;
<template>
    <div>
        <Header/>
        <div class="banner">
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="red">
                <van-swipe-item v-for="item in banner" :key="item.id">
                    <img :src="item.img+'?id='+Math.floor(Math.random() * 10)" />
                </van-swipe-item>
            </van-swipe>
        </div>

        <div class="con1 bor-bot">
            <div class="jiugg">
                <div class="gg-item">
                    <router-link to="/product">
                        <img src="@/assets/con1-1.png" alt="">
                        <p class="bt">车型库</p>
                        <p class="ms">美好的生活,从会买车开始</p>
                    </router-link>
                </div>
                <div class="gg-item">
                    <router-link to="/financlal">
                        <img src="@/assets/con1-2.png" alt="">
                        <p class="bt">会车金融</p>
                        <p class="ms">首付5%,2小时提车</p>
                    </router-link>
                </div>
                <div class="gg-item">
                    <router-link to="/join">
                        <img src="@/assets/con1-3.png" alt="">
                        <p class="bt">招商加盟</p>
                        <p class="ms">低首付,低利率,轻松购车</p>
                    </router-link>
                </div>
                <div class="gg-item">
                    <router-link to="/gy">
                        <img src="@/assets/con1-4.png" alt="">
                        <p class="bt">联系我们</p>
                        <p class="ms">各种优惠不断,期待您的发现</p>
                    </router-link>
                </div>
            </div>
        </div>

        <div class="con2 bor-bot padd012">
            <seamlessScroll />
        </div>

        <div class="con3 padd012 bor-bot">
            <div class="padd012">
            <div class="title">
                <h3>热销车型</h3>
                <p>Car sale</p>
            </div>
            <div class="carsale">
            <div v-for="item in carSale" :key="item.id">
                <product :img="item.img" :price="item.downPayment" :text="item.description" :title="item.modelName"/>
            </div>
            </div>
            <div class="btn">
                <router-link to="/product">MORE ></router-link>
            </div>
            </div>
        </div>

        <div class="con4 padd012">
            <div class="title">
                <h3>会买车优势</h3>
                <p>ADVANRAGE</p>
            </div>
            <div class="duibi-title">
                <div class="title-left item vs">传统4S店</div>
                <div class="title-cen item">会买车</div>
                <div class="title-right item vs">其他互联网平台</div>
            </div>
            <div class="duibi-lists">
                <div class="lists-li">
                    <div class="list-item">
                    <h3 class="ell cuo">品牌车型</h3>
                    <p class="multiline">
                       单一品牌<br/>
                        单一车型
                    </p>
                </div>
                <div class="list-item">
                    <h3 class="ell dui">品牌全,车型多</h3>
                    <p class="multiline">
                        国产品牌、合资品牌 
                        平行进口汽车、新能源汽车  
                        精品二手车
                    </p>
                </div>
                <div class="list-item acwu">
                    <h3 class="ell cuo">品牌车型</h3>
                    <p class="multiline">
                       品牌车型少 <br>
					多为折扣滞销车型
                    </p>
                </div>
                </div>
                <div class="lists-li">
                    <div class="list-item">
                    <h3 class="ell cuo">车源渠道</h3>
                    <p class="multiline">
                       单一授权、单一区域代理<br>    
					价格受厂商控制 <br>
					运营成本高.车价高 <br>
                    </p>
                </div>
                <div class="list-item">
                    <h3 class="ell dui">渠道广,车价低</h3>
                    <p class="multiline">
                        自有代理品牌，全国优势车源     <br>    
					直接国外进口                <br>                              
					运营成本低  
                    </p>
                </div>
                <div class="list-item acwu">
                    <h3 class="ell cuo">车源渠道</h3>
                    <p class="multiline">
                        购车渠道受限 <br>
                        利率高<br>
                        车价高<br>
                    </p>
                </div>
                </div>
                <div class="lists-li">
                    <div class="list-item">
                    <h3 class="ell cuo">购车方案</h3>
                    <p class="multiline">
                       金融方案单一
                    </p>
                </div>
                <div class="list-item">
                    <h3 class="ell dui">多种购车方案</h3>
                    <p class="multiline">
                        全款、按揭、0首付、低首付、融资租赁等多元化多金融方案  购车方式  
                    </p>
                </div>
                <div class="list-item acwu">
                    <h3 class="ell cuo">购车方案</h3>
                    <p class="multiline">
                       多为以租代购方式购车金融方案利率高
                    </p>
                </div>
                </div>
                <div class="lists-li">
                    <div class="list-item">
                    <h3 class="ell cuo">售后服务</h3>
                    <p class="multiline">
                       预约排队，费时费力流程繁琐，价格高昂
                    </p>
                </div>
                <div class="list-item">
                    <h3 class="ell dui">品牌全,车型多</h3>
                    <p class="multiline">
                        免费上门服务，在家轻松购车终身免费保养，消费向合伙人转变 ，买车还赚钱  
                    </p>
                </div>
                <div class="list-item acwu">
                    <h3 class="ell cuo">售后服务</h3>
                    <p class="multiline">
                       购车服务质量较低无售后服务
                    </p>
                </div>
                </div>
            </div>
        </div>

        <div class="footer">
            <fooTer/>
        </div>
    </div>
</template>

<script>
import Header from "@/components/header.vue";
import seamlessScroll from "@/components/home/seamlessScroll.vue";
import product from "@/components/product.vue";
import fooTer from "@/components/footer.vue";
import { Banner, carList } from "@/utils/requ";
export default {
  components: {
    Header,
    seamlessScroll,
    product,
    fooTer,
  },
  data() {
    return {
      banner: [],
      carSale: [],
      page: {
        current: 1,
        size: 4,
      },
    };
  },

  created() {
    this.getbanner();
  },
  methods: {
    async getbanner() {
      await Banner().then((res) => {
        console.log(res);
        this.banner = res.data.records;
      });

      await carList(this.page.current, this.page.size).then((res) => {
        this.carSale = res.data.records;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.bor-bot {
  border-bottom: 7px solid #f0f0f0;
}
.padd012 {
  padding: 0 10px 20px;
}
.banner {
  .my-swipe {
    text-align: center;
    img {
      max-height: none;
    }
  }
}
.con1 {
  .jiugg {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    .gg-item {
      flex: 1;
      text-align: center;
      padding: 20px 0;
      img {
        height: 50px;
        margin: 0 auto 10px;
      }
      .bt {
        font-size: 14px;
        color: #666;
      }
      .ms {
        font-size: 12px;
        display: none;
      }
    }
  }
}
.con2 {
  max-width: 1200px;
  margin: 0 auto;
}
.con3 {
  .padd012 {
    max-width: 1200px;
    margin: 0 auto;
    .title {
      padding: 20px 0 25px;
      text-align: center;
      font-size: 12px;
      color: #888888;
      text-transform: uppercase;
      h3 {
        font-size: 15px;
        color: #333333;
        letter-spacing: 2px;
      }
    }
    .btn {
      text-align: center;
      padding: 10px 0 0;
      height: 50px;
      line-height: 50px;
      a {
        color: #fff;
        background-color: #e6222a;
        font-size: 13px;
        padding: 0 15px;
        height: 30px;
        line-height: 30px;
        display: inline-block;
      }
    }
  }
}
.con4 {
  max-width: 1200px;
  margin: 0 auto;
  border-bottom: 7px solid #f0f0f0;
  padding-bottom: 0;
  .title {
    padding: 20px 0 25px;
    text-align: center;
    font-size: 12px;
    color: #888888;
    text-transform: uppercase;
    h3 {
      font-size: 15px;
      color: #333333;
      letter-spacing: 2px;
    }
  }
  .duibi-title {
    padding: 20px 0 25px;
    display: flex;
    .item {
      flex: 1;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 15px;
      color: white;
      background-color: #e6232b;
    }
    .vs {
      &::after {
        content: "";
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        background: #fff url("@/assets/vs.jpg") no-repeat center;
        background-size: 60% auto;
      }
    }
    .title-left {
      border-radius: 40px 0 0 40px;
      background-color: #4d4d4d;
      position: relative;
      &::after {
        top: 0;
        right: 0;
        transform: translate(50%, 0);
      }
    }

    .title-cen {
    }
    .title-right {
      position: relative;
      text-align: right;
      padding-right: 10px;
      border-radius: 0 40px 40px 0;
      font-size: 12px;
      &::after {
        left: 0;
        top: 0;
        transform: translate(-50%, 0);
      }
    }
  }
  .duibi-lists {
    .lists-li {
      display: flex;
      .list-item {
        flex: 1 1 33.33%;
        box-sizing: border-box;
        padding: 0 10px 15px;
        position: relative;
        &::after {
          content: "";
          display: block;
          width: 1px;
          height: 100%;
          background: #adadad;
          position: absolute;
          top: 0;
          right: 0;
        }
        &.acwu {
          &::after {
            width: 0;
          }
        }
        .cuo {
          &::after {
            content: "";
            display: block;
            width: 15px;
            height: 15px;
            background: url("@/assets/cuo.png") no-repeat center;
            background-size: 100%;
            position: absolute;
            top: 49%;
            left: 0;
            transform: translate(0, -51%);
          }
        }
        .dui {
          color: #e6222a;
          &::after {
            content: "";
            display: block;
            width: 15px;
            height: 15px;
            background: url("@/assets/dui.png") no-repeat center;
            background-size: 100%;
            position: absolute;
            top: 49%;
            left: 0;
            transform: translate(0, -51%);
          }
        }
        h3 {
          font-size: 13px;
          font-weight: 500;
          color: #4d4d4d;
          line-height: 19px;
          padding-left: 18px;
          position: relative;
          margin-bottom: 20px;
        }
        p {
          height: 54px;
          line-height: 18px;
          font-size: 12px;
          color: #999;
          -webkit-line-clamp: 3;
        }
      }
    }
  }
}
@media all and(min-width:640px) {
  .bor-bot {
    border-bottom: none;
  }
  .banner {
    .my-swipe {
      text-align: center;
      max-height: 900px;
    }
  }
  .con1 {
    border-bottom: none;
    background: url("@/assets/bg1.jpg") no-repeat center center;
    overflow: hidden;
    padding: 50px 0;
    .jiugg {
      .gg-item {
        img {
          height: 60px;
        }
        .bt {
          margin: 20px 0 10px;
          font-size: 16px;
          color: #fff;
        }
        .ms {
          color: #fff;
          display: block;
        }
      }
    }
  }
  .con3 {
    background: url("@/assets/bg2.jpg") no-repeat center center;
    .padd012 {
      .title {
        color: #fff;
        padding: 50px 0;
        font-size: 28px;
        h3 {
          font-size: 30px;
          margin-bottom: 15px;
          color: #fff;
        }
      }
      .carsale {
        display: flex;
        justify-content: space-around;
        div {
          max-width: 260px;
          background: #fff;
        }
      }
    }
  }
  .con4 {
    .title {
      padding: 50px 0;
      font-size: 28px;
      h3 {
        font-size: 30px;
        margin-bottom: 15px;
      }
    }
    .duibi-title {
      .item {
        font-size: 22px;
      }
      .title-right {
        text-align: center;
      }
    }
    .duibi-lists {
      .lists-li {
        .list-item {
          h3 {
            font-size: 18px;
          }
          p {
            font-size: 16px;
            line-height: 1.8;
          }
        }
      }
    }
  }
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/product',
    name: 'product',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/Product'),
  },
  {
    path: '/product/prodeta/:id',
    name: 'prodeta',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Prodeta'),
  },
  {
    path: '/financlal',
    name: 'financlal',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Financlal'),
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "about" */ '@/views/NewList'),
  },
  {
    path: '/news/info/:id',
    name: 'newsInfo',
    component: () => import(/* webpackChunkName: "about" */ '@/views/NewsInfo'),
  },
  {
    path: '/gy',
    name: 'gy',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Gy'),
  },
  {
    path: '/join',
    name: 'join',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Join'),
  },
  {
    path: '/down',
    name: 'down',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Downs'),
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
    <div>
        <div class="goods">
            <div class="goods-left">
                <img :src="img">
                <span></span>
            </div>
            <div class="goods-right">
                <h3>{{title}}</h3>
                <p> {{text}}</p>
                <div>
                    <span>首付价格:&nbsp;&nbsp;<b>¥{{price}}</b></span>
                    <!-- <del>指导价:¥{{guide}}万</del> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            img:{
                type:String,
                require:true
            },
            title:{
                type:String,
                require:true
            },
            text:{
                type:String,
                require:true
            },
            price:{
                type:String,
                require:true
            },
            guide:{
                type:String,
                require:true
            }
        }
    }
</script>

<style lang="less" scoped>
.goods{
    display: flex;
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom:1px solid #eee;
    .goods-left{
        margin-right: 10px;
        position: relative;
        img{
            width:135px;
            height: 100px;
            border: 1px solid #ccc;
        }
        span{
            display: block;
            width: 27px;
            height: 27px;
            background: url(@/assets/hot.png)no-repeat center;
            background-size: 100%;
            position:absolute;
            left: 0;
            top: 0;
        }
    }
    .goods-right{
        flex: 1;
        h3{
            font-size: 15px;
            color:#333;
        }
        p{
            font-size: 14px;
            color:#666;
            margin-top: 5px;
        }
        div{
            font-size: 12px;
            color:#999;
            margin-top: 5px;
            span{
                margin-right: 10px;
                b{
                    color:#e6222a;
                    font-weight: 500;
                    font-size: 16px;
                }
            }
        }
    }
}
@media all and(min-width:640px) {
    .goods{
        border-bottom: none;
        flex-direction: column;
        .goods-left{
            img{
                width: auto;
                height: auto;
                border:none;
            }
        }
        .goods-right{
            padding:0 15px;
            h3{
                margin-top: 10px;
            }
            p{
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin:10px 0
            }
        }
    }
}
</style>
<template>
    <div>
        <div class="h60"></div>
        <div class="tabbar">
            <div class="tab-item" @click="home">
                <img src="@/assets/tab1.png">
                <p>首页</p>
            </div>
            <div class="tab-item" @click="cart">
                <img src="@/assets/tab2.png">
                <p>车型</p>
            </div>
            <div class="tab-item" @click="join">
                <img src="@/assets/tab3.png">
                <p>加入</p>
            </div>
            <div class="tab-item" @click="gy">
                <img src="@/assets/tab4.png">
                <p>关于</p>
            </div>
        </div>
        <div class="model" v-show="formshow">
            <div class="model-icon">
                <img src="@/assets/t.jpg">
            </div>
            <div class="model-close" @click="gbclose">
               <van-icon name="clear" size="26" />
            </div>
            <div class="model-header">
                <h3>会买车专业顾问将第一时间为您服务</h3>
                <p>请留下您的意向车型和联系方式</p>
            </div>
            <div class="model-body">
                <van-form @submit="onSubmit">
                    <van-field
                        v-model="form.intendedModel"
                        name="intendedModel"
                        label="意向车型："
                        placeholder="意向车型"
                        :rules="[{ required: true, message: '请填写意向车型' }]"
                    />
                    <van-field
                        v-model="form.name"
                        name="name"
                        label="您的名字："
                        placeholder="您的名字"
                        :rules="[{ required: true, message: '请填写您的名字' }]"
                    />
                    <van-field
                        v-model="form.phone"
                        name="phone"
                        label="联系电话："
                        placeholder="联系电话"
                        :rules="[{ required: true, message: '请填写您的电话' },{validator:verifyPhone,message:'请输入正确的手机号码'}]"
                    />
                    <div style="margin: 16px;">
                        <van-button block type="info" native-type="submit" size="small">提交</van-button>
                    </div>
                </van-form>
            </div>
        </div>
        <div class="zx" @click="formshow=true"></div>
    </div>
</template>

<script>
import {onlinemessage} from '@/utils/requ'
    export default {
        data(){
            return{
                formshow:false,
                form:{
                    intendedModel:'',
                    name:'',
                    phone:''
                }
            }
        },
        methods:{
            async onSubmit(e){
                console.log(e);
                this.$toast.loading({
                    message:'提交中',
                    overlay:true,
                    duration:0
                })
                await onlinemessage(this.form).then(res=>{
                    console.log(res);
                    if(res.code===200){
                        this.$toast.clear()
                        this.$toast.success('提交成功');
                        this.gbclose()
                    }
                })
            },
            gbclose(){
                this.formshow = false
                this.form={
                    cx:'',
                    name:'',
                    phone:''
                }
            },
            verifyPhone(val){
                return /^1\d{10}$/.test(val)
            },
            home(){
                if(this.$route.path==='/'){
                    return;
                }
                this.$router.push('/')
            },
            cart(){
                if(this.$route.path==='/product'){
                    return;
                }
                this.$router.push('/product')
            },
            join(){
                if(this.$route.path==='/join'){
                    return;
                }
                this.$router.push('/join')
            },
            gy(){
                if(this.$route.path==='/gy'){
                    return;
                }
                this.$router.push('/gy')
            }
        }
    }
</script>

<style lang="less" scoped>
.tabbar{
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 50px;
    background: url('@/assets/f.gif') repeat-x center center;
    display: flex;
    // justify-content: space-between;
    .tab-item{
        flex: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        img{
            height: 24px;
        }
        p{
            color: white;
            font-size: 13px;
        }
    }
}
.model{
    width: 300px;
    height: 365px;
    background: white;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 6px;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 10;
    transform: translate(-50%,-50%);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    .model-icon{
        width: 79px;
        height: 79px;
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        top: -39.5px;
        left: 50%;
        transform: translate(-50%,0);
    }
    .model-close{
        position: absolute;
        top: 10px;
        right: 10px;
    }
    .model-header{
        padding-top: 40px;
        text-align: center;
        h3{
            font-size: 15px;
            color: #333;
        }
        p{
            font-size: 13px;
            color: #666;
            margin-top: 5px;
        }
    }
    .model-body{
        margin-top: 25px;
        padding:0 10px;
    }
}
.zx{
    width: 51px;
    height: 51px;
    position: fixed;
    right: 10px;
    bottom: 70px;
    background: url('@/assets/zx.png')no-repeat center;
    background-size: 100%;
}
@media all and(min-width:640px) {
    .tabbar,.zx{
        display: none;
    }
}
</style>